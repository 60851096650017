
.form-group {
    margin-bottom: 25px;
  }
  
  .form-group label {
    display: block;
  }
  
  .form-group label,
  .label {
    color: #9b9b9b;
    flex-basis: auto;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 25px;
  }
  
  .radio {
    accent-color: #97144d;
  }
  
  .form-group .new-control {
    width: 95%;
    padding: 5px;
    font-size: 16px;
    font-family: "Work Sans", sans-serif;
    font-weight: 400;
    color: black;
    background-color: #f9f9fb;
    caret-color: #a6004d;
    border-style: none;
    border-bottom: 1px solid #adbdcc;
    line-height: 20px;
  }
  
  .form-group .new-control:focus {
    border-style: none;
    outline: none;
    border-bottom: 1px solid #adbdcc;
  }
  
  .submit {
    background-color: #2400a6;
    color: white;
    text-transform: uppercase;
    border-radius: 25px;
    padding: 10px;
    border: none;
    outline: none;
    cursor: pointer;
    width: 60%;
    font-size: 15px;
    font-family: "Work Sans", sans-serif;
    font-weight: 600;
    margin-top: 25px;
  }
  
  .text-center{
    display: flex;
    align-items: center;
    justify-content: center;
  }



  .box{
    background-color: aliceblue;
    margin: 2px;
    padding-inline: 12px;
    padding-top: 1px;
    padding-bottom: 1px;
}