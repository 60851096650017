
.f-box{
text-align: center;
height: 40vh;
width:60vw ;
background-color: aqua;
border: 1px solid black;

}

.flex{
    display: flex;
    align-items: center;
    justify-content: center;
}

.input{
    margin: 2px;
    padding: 2px;

}

