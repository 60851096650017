
.widgets {
    display: flex;
    gap: 20px;
  }
  
  .widget {
    flex: 1;
    background: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .widget h3 {
    margin-top: 0;
  }